import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppDataService } from './services/appData/app-data.service';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

export function appData(appData: AppDataService) {
  return () => appData.init();
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule], providers: [PDFGenerator, FileOpener, File,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, {
            provide: APP_INITIALIZER,
            useFactory: appData,
            multi: true,
            deps: [AppDataService],
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
